let password_c = document.getElementById('pw1');
let password_c2 = document.getElementById('pw2');
let submit = document.getElementById('submit');
let StrengthDisp_ = document.getElementById('StrengthDisp');
let c, c2 = null;
import double_check from './passwordstrenght.js';

if (typeof (double_check) != 'undefined' && double_check != null) {
    //console.log('double_check bestaat' );
} else {
    //console.log('double_check bestaat NIET' );
}
if (typeof (password_c) != 'undefined' && password_c != null) {
  // timeout before a callback is called

  let timeout_c;

  // traversing the DOM and getting the input and span using their IDs

  //let password = document.getElementById('PassEntry')
  

  // The strong and weak password Regex pattern checker

  

  function sameChecker(c,c2) {
    // 
      if (c === c2) {
          //alert('Yolo!');
        //   console.log(c);
        //   console.log(c2);
        //   console.log('---- HIJ IS GELIJK!======');
          if (StrengthDisp_.innerHTML != 'Weak') {
              submit.removeAttribute('disabled'); 
          } else {
              //console.log('Password is stll weak');
          }
          
      } else {
          //console.log('---- HIJ NIET GELIJK!======');
          submit.setAttribute('disabled',true);
      }
  }

  // Adding an input event listener when a user types to the  password input 

  password_c2.addEventListener("input", () => {
       c = password_c.value;
      c2 = password_c2.value;
    //The badge is hidden by default, so we show it
      console.log('checking....');
    //strengthBadge.style.display = 'block'
      clearTimeout(timeout_c);

    //We then call the StrengChecker function as a callback then pass the typed password to it

      timeout_c = setTimeout(() => sameChecker(c,c2), 100);

    //Incase a user clears the text, the badge is hidden again

    // if (password.value.length !== 0) {
    //   strengthBadge.style.display != 'block'
    // } else {
    //   strengthBadge.style.display = 'none'
    // }
  });
}