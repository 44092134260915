

import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
//* import the Swiper styles
// import 'swiper/css';


// // import styles bundle
import 'swiper/css/bundle'; // alles!
// import 'swiper/css'; // core!
// import 'swiper/css/pagination'; // core!

function enToAmps(str) {
    let updatedText = str.replace(/&/g, "&amp;");
    return updatedText;  // Output: piano &amp; toetsen
}


let swiper;
function innitSwiper() {

    swiper = new Swiper('.disc-mobile', {
        // Optional parameters
        //direction: 'vertical',
        slidesPerView: 'auto',
        loop: false,
        autoHeight: true,
        spaceBetween: 0,
        //wrapperClass: 'wp-block-gallery',
        //slideClass: 'wp-block-image',
        //containerModifierClass:'wp-block-gallery',
        // If we need pagination
        // centeredSlidesBounds: true,
        // centeredSlides: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        //cssMode:true,
        keyboard: {
            enabled: true, // Enable keyboard control
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },


        // And if we need scrollbar
        // scrollbar: {
        //     el: '.swiper-scrollbar',
        //     draggable: true
        // },
        on: {
            init: function () {
                // Swiper is ready

                console.log('Swiper is ready');
                //innitBackdrop();
            }
        }
    });

    
}


innitSwiper();

function isMobile() {
    return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|webOS/i.test(navigator.userAgent) || window.innerWidth <= 768;
}

if (isMobile()) {
    console.log("Mobile device detected");
} else {
    console.log("Desktop device detected");
}

/* Autocomplete en fuzzy search */
import Fuse from 'fuse.js';
//const Fuse = require('fuse.js');



//const baseUrl = '/wp-content/themes/paa/modules/autocomplete/';
const baseUrl = '/wp-content/uploads/';
// const data = [
//     "apple",
//     "banana",
//     "orange",
//     "gitaar",
//     "Viool",
//     "grape",
//     "pineapple",
//     "strawberry",
//     "blueberry",
//     "watermelon"
// ];





document.addEventListener("DOMContentLoaded", () => {
    const input = document.getElementById("autocomplete-input");
    const suggestionsContainer = document.getElementById("autocomplete-suggestions");
    const form = document.getElementById("autocomplete-form");
    //const logo = document.querySelector(".logo-ikleer-je");

    let data = [];
    let currentIndex = -1;
    let filteredData = null;  

    // logo.addEventListener('click', function () {

    // });

    // Fetch data from JSON file
    fetch(baseUrl + 'acf-data.json')
        .then(response => response.json())
        .then(jsonData => {
            data = jsonData;
            do_fuzzy(data);
        })
        .catch(error => {
            console.error('Error loading JSON data:', error);
        });

    input.addEventListener("input", () => {
        const query = input.value.toLowerCase();
        suggestionsContainer.innerHTML = "";
        currentIndex = -1;

        if (query.length > 0) {
            filteredData = data.filter(item => item.toLowerCase().includes(query));
            console.log(filteredData);
            var amout = filteredData.length;
            if (amout <= 0) {
                console.log('geen class');
                removeRsultClass();
            } else {
                console.log('wel class');
                addRsultClass();
            }
            filteredData.forEach(item => {

                const suggestion = document.createElement("div");
                suggestion.classList.add("autocomplete-suggestion");
                suggestion.innerHTML = highlightMatch(item, query);
                suggestionsContainer.appendChild(suggestion);

                suggestion.addEventListener("click", () => {
                    input.value = item;
                    suggestionsContainer.innerHTML = "";
                    form.submit();
                });
            });
        } else {
            console.log('geen class!!!');
            removeRsultClass();

        }
    });

    input.addEventListener("keydown", (e) => {
        const suggestions = document.querySelectorAll(".autocomplete-suggestion");
        if (e.key === "ArrowDown") {
            if (currentIndex < suggestions.length - 1) {
                currentIndex++;
                updateSelection(suggestions);
            }
        } else if (e.key === "ArrowUp") {
            if (currentIndex > 0) {
                currentIndex--;
                updateSelection(suggestions);
            }
        } else if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission on Enter key
            if (currentIndex >= 0 && currentIndex < suggestions.length) {
                input.value = suggestions[currentIndex].textContent;
                suggestionsContainer.innerHTML = "";
                currentIndex = -1;
            }
            form.submit();
        }
    });

    // document.addEventListener("click", (e) => {
    //   if (suggestionsContainer) {
    //     let closeButton = document.querySelector('.btn-close-layer');
    //     if (e.target !== input && e.target !== closeButton) {
    //       suggestionsContainer.innerHTML = "";
    //       currentIndex = -1;
    //       removeRsultClass();
    //     }
    //   }
    // });




    function removeRsultClass() {
        form.classList.remove('has-results');
    }
    function addRsultClass() {
        form.classList.add('has-results');
    }
    function updateSelection(suggestions) {
        suggestions.forEach((suggestion, index) => {
            if (index === currentIndex) {
                suggestion.classList.add("selected");
                suggestion.style.scrollMarginTop = '100px'
                suggestion.scrollIntoView({ behavior: 'auto', block: 'nearest' });
            } else {
                suggestion.classList.remove("selected");
            }
        });
    }

    function highlightMatch(text, query) {
        const regex = new RegExp(`(${query})`, 'gi');
        return text.replace(regex, '<span class="highlight">$1</span>');
    }
    
});
/* auto complete */

/* fuzzy search */
function do_fuzzy(data) {
    const searchString = document.querySelector('.search-field');
    //const notFound = document.querySelector('.result-wr-did-you-mean');
    const notFound = document.querySelector('.did-you-mean-fuse');
    const rw = document.querySelector('.result-wr-did-you-mean');
    let nothingFound = false;
    if (rw) {
        rw.style.display = 'none';   
        if (rw.innerHTML != '') {
            nothingFound = true;
            

        }
    }
    let resultOutput = [];
    if (notFound) {
        const fuseOptions = {
            isCaseSensitive: false,
            includeScore: true,
            shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            // threshold: 0.6,
            // distance: 100,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            // fieldNormWeight: 1,
            // keys: [
            //     "title"
            // ]
        };
        //data = JSON.parse(data);
        //console.log(data);
        const fuse = new Fuse(data, fuseOptions);

        // Change the patter
        //console.log('searchString' + searchString);
        let result = fuse.search(enToAmps(searchString.value));
        
        let output = '';
        console.log('----->',result[0].score);
        if (result.length != 0 && result[0].score!=0) {
            result.forEach((element,index) => {
                console.log(element.score);
                if (element.score <= 0.2) {
                    resultOutput[index] = element.score;
                }
            });
            
            if (resultOutput.length >= 1) {
                // console.log(resultOutput);
                let resultL = resultOutput.length;
                
                let sep = '';
                resultOutput.forEach((ele, index) => { 
                    if (index == (resultL - 1) && index!=0) {
                        sep = ' of '
                    } else if(index!=0) {
                        sep = ', ';
                    }
                    //console.log('!!'+ index);
                    output += sep+'<a href="/?ikleerje=' + result[index]['item'] + '#r">' + result[index]['item'] + '</a>';
                    //console.log(ele);
                   
                    
                });

            } else {
                output = '<a href="/?ikleerje=' + result[0].item + '#r">' + result[0].item + '</a>';
            }
            notFound.innerHTML = '<div>Bedoelde je soms '+ output +'?</div>';
        } else if (nothingFound) {
            notFound.innerHTML = '<div>Niets gevonden.</div>';
        }
        

        console.log(result);
    }
}




document.addEventListener('DOMContentLoaded', function () {
    let Aitems = document.querySelectorAll('.item-wr .item');

    function hideItemsShowCurrent(Aitems, current) {
        Aitems.forEach(Aitem => {
            Aitem.classList.add('fade-item');
            Aitem.classList.remove('active');
            current.classList.remove('fade-item');
            current.classList.add('active');
        });
    }
    function restoreAll(Aitems) {
        Aitems.forEach(Aitem => {
            Aitem.classList.remove('fade-item');
            Aitem.classList.remove('active');
        });
    }

    Aitems.forEach(Aitem => { 
        Aitem.addEventListener('mouseover', () => { 
            hideItemsShowCurrent(Aitems, Aitem);
        });
        Aitem.addEventListener('mouseleave', () => {
            restoreAll(Aitems);
        });
    });

});


/* Ajax loading script results */

document.addEventListener('DOMContentLoaded', function () {
    const items = document.querySelectorAll('.btn-item-ilj');
    const layer = document.getElementById('ajax-layer'); // Custom layer container
    var scrollpos = null;
    let previousUrl = window.location.href; // Store the initial URL

    // Function to close the layer and revert the URL
    function closeLayer() {
        console.log('close layer');
        layer.classList.remove('show-layer'); // Hide the layer
        document.body.classList.remove('no-scroll'); // Enable scrolling

        // Revert the URL to the original one before opening the layer
        window.history.replaceState({ path: previousUrl }, '', previousUrl); // Replace the current history state with the original URL
    }

    // Function to open the layer
    function openLayer(html, url) {
       
        console.log('open layer');
        
        const scrollableDiv = document.querySelector('.overflow-scroller'); // Replace with your div's class
        scrollableDiv.scrollTop = 0;

        // Insert the content into the custom layer and display the layer
        document.getElementById('content').innerHTML = `<button type="button" class="btn-close-layer"></button>` + html;
        //layer.style.display = 'block'; // Show the layer
        layer.classList.add('show-layer');
        document.body.classList.add('no-scroll'); // Disable scrolling

        // Change the URL without reloading the page
        window.history.pushState({ path: url }, '', url);

        // Add a listener to the dynamically created close button
        const sac = document.querySelector('.overflow-scroller');
        sac.addEventListener('scroll', () => { window.scrollBy(0, 100); }, { once: true });


        let newCloseButton = layer.querySelector('.btn-close-layer');
        console.log(newCloseButton);
        newCloseButton.addEventListener('click', function () {
            closeLayer();
        });
    }

    // Handle click events for each item
    items.forEach(item => {
        item.addEventListener('click', function (event) {
            event.preventDefault();

            const url = this.href; // Extract the href from the clicked button

            // Load the content via Ajax
            fetch(url)
                .then(response => response.text())
                .then(html => {
                    // Parse the fetched HTML string
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(html, 'text/html');

                    // Extract the specific content from #aanbieder-content
                    const aanbiederContent = doc.querySelector('#aanbieder-content');

                    if (aanbiederContent) {
                        openLayer(aanbiederContent.innerHTML, url);
                    } else {
                        console.error('Content with id #aanbieder-content not found on the page');
                    }
                })
                .catch(error => console.error('Error loading content:', error));
        });
    });

    // Handle the back button (popstate event)
    window.addEventListener('popstate', function () {
        if (window.location.href === previousUrl) {
            // If the current URL matches the original (before opening the layer), close the layer
            closeLayer();
        } else if (layer.classList.contains('show-layer')) {
            // If the layer is still open and user pressed back, close the layer
            closeLayer();
        }
    });

    // Handle the Esc key to close the layer
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            closeLayer();
        }
    });

    const ajaxLayer = document.querySelector('#ajax-layer');

    ajaxLayer.addEventListener('click', function (event) {

        // Check if the click is on the .ajax-layer and not on any of its child elements

        if (event.target === ajaxLayer) {
            console.log('Clicked on .ajax-layer backdrop');
            closeLayer();
            // Your logic here, e.g., close the modal
        } else {

        }
    });
});







/* -- */



window.addEventListener('pageshow', function (event) {
    if (event.persisted) {
        // This ensures that the back button uses the cached page and not a fresh reload
        //console.log('event persisted');
        window.location.reload();
    } else {
        //console.log('event not persisted');
    }
});
window.addEventListener('pageshow', function (event) {
    if (!event.persisted) {
        console.log('Page not loaded from cache; fresh reload.');
    } else {
        console.log('Page loaded from cache; no reload needed.');
    }
});



document.addEventListener('DOMContentLoaded', function () {
    read_more_disciplines();
});

function read_more_disciplines() {
    // Attach event listener to a parent element (e.g., document or a specific container)
    document.addEventListener('click', function (e) {
        // Check if the clicked element has the 'show-more' class
        if (e.target && e.target.classList.contains('show-more')) {
            e.preventDefault();

            // Find the closest `.ilj_disciplines` element and then the `.more-terms` within it
            const showMoreLink = e.target;
            const hiddenTerms = showMoreLink.closest('.ilj_disciplines').querySelector('.more-terms');

            if (hiddenTerms) {
                const isHidden = hiddenTerms.style.display === 'none' || hiddenTerms.style.display === '';
                hiddenTerms.style.display = isHidden ? 'inline' : 'none';
                showMoreLink.textContent = isHidden ? 'Toon minder' : 'Toon meer';
            }
        }
    });
}


document.addEventListener('DOMContentLoaded', function () {

    const stickyElement = document.querySelector('.zoeken-ikleerje');

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.intersectionRatio < 1) {
                // Element is sticky
                console.log('Element has become sticky!');
            } else {
                // Element is not sticky
                console.log('Element is not sticky anymore.');
            }
        },
        { threshold: [1] } // Trigger when the element's full height is no longer in view
    );

    observer.observe(stickyElement);
});



document.addEventListener("DOMContentLoaded", () => { 
    //focus the search fiels
    if (!isMobile()) {
        const searchField = document.querySelector('body.ikleer-je .search-field');
        if (searchField) {
            searchField.focus();
        }
    } 
    

    // const swiper = new Swiper('.swiper', {
    //     speed: 400,
    //     spaceBetween: 100,
    // });

    

    const swiper = new Swiper();

    
});

